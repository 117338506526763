<template>
	<b-form inline>
		<label class="mr-2" for="inline-form-input-name">What is</label>
		<b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0">
			<b-form-input style="width: 80px;" id="inline-form-input-username" v-model="value1"></b-form-input>
		</b-input-group>

		<label class="mr-2" for="inline-form-input-username">of</label>
		<b-form-input
			id="inline-form-input-name"
			class="mb-2 mr-sm-2 mb-sm-0"
			v-model="value2"
			style="width: 115px;"
		></b-form-input>
		<label class="mr-2" for="inline-form-input-username">?</label>

		<p class="alert alert-info mb-0 ml-3">
			<template v-if="value1 && value2">
				{{ result }}
			</template>
			<template v-else>
				Nothing to calc yet
			</template>
		</p>
	</b-form>
</template>

<script>
export default {
	name: 'PercentOf',
	props: {
	},
	computed:{
		result(){
			if(!this.value1 || !this.value2){
				return null
			}
			return this.value2/100*this.value1
		}
	},
	data(){
		return {
			value1: null,
			value2: null
		}
	},

}
</script>