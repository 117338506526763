<template>
	<div id="app">
		<div class="">
			<div class="mb-5">
				<PercentOf/>
			</div>
			<div class="mb-5">
				<IsPercent/>
			</div>
			<div class="mb-5">
				<ChangePercent/>
			</div>
			<div class="mb-5">
				<HundredByPart/>
			</div>
		</div>
	</div>
</template>

<script>
import PercentOf from './components/PercentOf.vue'
import IsPercent from './components/IsPercent.vue'
import ChangePercent from './components/ChangePercent.vue'
import HundredByPart from './components/HundredByPart.vue'

export default {
	name: 'App',
	components: {
		PercentOf,
		IsPercent,
		ChangePercent,
		HundredByPart
	}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
