<template>
	<b-form inline>
		<b-form-input style="width: 80px;" id="inline-form-input-username" class="mr-2" v-model="value1"></b-form-input>

		<label class="mr-2" for="inline-form-input-name"> is what percent of</label>
		<b-form-input
			id="inline-form-input-name"
			class="mb-2 mr-sm-2 mb-sm-0"
			v-model="value2"
			style="width: 115px;"
		></b-form-input>
		<label class="mr-2" for="inline-form-input-username">?</label>

		<p class="alert alert-info mb-0 ml-3">
			<template v-if="value1 && value2">
				{{ result }}%
			</template>
			<template v-else>
				Nothing to calc yet
			</template>
		</p>
	</b-form>
</template>

<script>
export default {
  name: 'IsPercent',
  props: {
  },
  computed:{
	result(){
	  if(!this.value1 || !this.value2){
		return null
	  }

	  return this.value1/(this.value2/100)
	}
  },
  data(){
	return {
	  value1: null,
	  value2: null
	}
  },

}
</script>